<!--
 * @Descripttion: 写日志
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-17 15:19:24
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-08-21 18:16:04
-->
<template>
  <GoBack >
    <template #rightContent>
      <div class="sync-okr">
        <el-switch v-model="syncOkr" :width="26" @change="changeSyncOkr" />
        <span class="sync-okr-title">日志同步OKR</span>
        <span class="sync-okr-desc">开启后，日志会同步展示OKR内容</span>
      </div>
    </template>
  </GoBack>
  <div class="write-journal-container">
    <div class="write-journal-wraper">
      <div class="journal-wraper">
        <div class="history-journal" v-loading="isLoading">
          <template v-if="!isLoading">
            <JournalContent :leftScroll="true" :onlyPreview="true" :journalList="historyJournal" :templateName="templateName" @import="importContent">
              {{ hasMore }}
              <template #slot>
                <p class="more" v-if="hasMore" @click="loadMore">查看更多</p>
              </template>
            </JournalContent>
          </template>
        </div>
        <div class="my-journal border-6">
          <div class="select-template" v-if="route.name !== 'updateJournal'">
            <ul class="journal-tab" ref="journalTabRef">
              <li @click.stop="selectTemplate('其他')" :class="['border-6']">
                <el-tooltip class="box-item" effect="dark" :content="templateName" placement="top-start">
                  <span class="tpl-name">{{templateName}}</span>
                </el-tooltip>
                <img src="@/assets/img/arrow-down.png" />
              </li>
            </ul>
          </div>
          <template v-if="templateVisible">
            <div class="template-list border-6" ref="templateListRef">
              <svg :class="{'disable': activeIndex === 0}"
                @click="prev" width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.853 33.832L2.463 18l8.39-15.832" stroke="#C4C4C4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
              <template v-for="(template, index) in otherTemplateList" :key="template.template_id">
                <JournalTemplate
                  v-if="template.isActive"
                  :template="template"
                 @click="selectTemplate(template.template_name)">
                 {{index}}
                </JournalTemplate>
              </template>
              <svg :class="{'disable': activeIndex === otherTemplateList.length - 4 || otherTemplateList.length < 4}"
                @click="next"
                width="13" height="36" viewBox="0 0 13 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.15 33.832L10.54 18 2.15 2.168" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
            </div>
          </template>
          <WriteJournal :name="templateName" ref="writeJournalRef"></WriteJournal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, computed } from 'vue'
import JournalTemplate from './component/JournalTemplate'
import WriteJournal from './component/WriteJournal'
import JournalContent from '../component/JournalContent'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getJournalList, getTemplateList, updateWithOkr } from '@/apis/journal.js'
import { dialogMsg } from '@/utils/dialogMsg.js'

export default {
  components: {
    JournalContent,
    JournalTemplate,
    WriteJournal
  },
  setup () {
    onMounted(() => {
      getTemplate()
      getHistoryJournal()
      // 监听鼠标点击事件, 关闭选择模板弹窗
      document.addEventListener('click', (e) => {
        if (state.templateListRef && !state.templateListRef.contains(e.target)) {
          state.templateVisible = false
        }
      })
    })
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      templateVisible: false,
      templateList: [], // 常用模板
      templateName: '', // 选择的模板
      otherTemplateList: [], // 其他模板
      activeIndex: 0, // 其他模板
      historyJournal: [], // 我发出的历史日志
      isLoading: true,
      params: {
        page: 1,
        limit: 5,
        type: 1
      },
      hasMore: true,
      templateListRef: null,
      journalTabRef: null,
      writeJournalRef: null,
      syncOkr: computed({
        get () {
          return !!state.writeJournalRef?.withOkr
        },
        set (val) {
          state.writeJournalRef.withOkr = Number(val)
        }
      })
    })

    // 获取模板列表
    const getTemplate = () => {
      state.templateList = JSON.parse(JSON.stringify(store.state.work.templateList.use_template))
      if (state.templateList.length > 0) { // vuex里面有数据
        setTemplateList()
      } else { // vuex里面没有数据，重新查询
        queryTemplateList()
      }
    }
    const queryTemplateList = () => {
      const data = { user_id: store.state.userInfo.userInfos.user_id }
      getTemplateList(data).then(res => {
        if (res.code === 0) {
          if (res.data.use_template && res.data.use_template.length > 0) {
            state.templateList.push(...res.data.use_template)
            store.commit('work/setTemplateList', {
              use_template: res.data.use_template,
              no_use_template: res.data.no_use_template
            })
          } else { // 从未写过日志
            const list = res.data.no_use_template
            store.commit('work/setTemplateList', {
              use_template: list.slice(0, 4),
              no_use_template: list.slice(4)
            })
          }
          getTemplate()
        }
      })
    }

    const setTemplateList = () => {
      state.otherTemplateList = [...store.state.work.templateList.use_template, ...store.state.work.templateList.no_use_template]
      state.otherTemplateList.forEach((item, index) => {
        if (index < 4) {
          item.isActive = true
        } else {
          item.isActive = false
        }
      })
      state.templateName = state.templateList[0].template_name
      state.activeIndex = 0
    }

    // 选择模板
    const selectTemplate = (templateName) => {
      if (templateName === '其他') {
        state.templateVisible = true
      } else {
        state.templateName = templateName
        state.templateVisible = false
      }

      setToolbar()
    }
    // 获取历史日志
    const getHistoryJournal = () => {
      getJournalList(state.params).then(res => {
        state.isLoading = false
        if (res.code === 0) {
          state.historyJournal.push(...res.data.data)
          state.hasMore = res.data.data.length === 5
        }
      }).catch(() => {
        state.isLoading = false
      })
    }
    const next = () => {
      if (state.activeIndex < state.otherTemplateList.length - 4) {
        state.activeIndex++
        setActiveTemplate()
      }
    }
    const prev = () => {
      if (state.activeIndex > 0) {
        state.activeIndex--
        setActiveTemplate()
      }
    }

    // 设置展示模板列表
    const setActiveTemplate = () => {
      state.otherTemplateList.forEach((item, index) => {
        if (index >= state.activeIndex && index < state.activeIndex + 4) {
          item.isActive = true
        } else {
          item.isActive = false
        }
      })
    }
    // 查看更多
    const loadMore = () => {
      state.params.page++
      getHistoryJournal()
    }
    // 导入日志
    const importContent = (data) => {
      state.writeJournalRef.updateJournalData(data, 'import')
      dialogMsg('success', '导入日志成功')
    }

    /**
     * 日志同步OKR 掉接口查询okr
     */
    const changeSyncOkr = (val) => {
      updateWithOkr({ template_id: state.writeJournalRef?.template.template_id }).then(res => {
        if (res.code === 0 && val) {
          state.writeJournalRef.getLogOkrData()
        }
      })
      setToolbar()
    }

    const setToolbar = () => {
      document.querySelectorAll('.journal-item .w-e-toolbar').forEach((i, index) => {
        if (index === 0) { i.style.display = 'flex' }
      })
    }
    return {
      ...toRefs(state),
      selectTemplate,
      next,
      prev,
      loadMore,
      route,
      importContent,
      changeSyncOkr
    }
  }
}
</script>
<style lang="less" scoped>
  .select-template{
    position: relative;
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 2;
    padding-top: 5px;

    .box-item {
      height: 100%;
      line-height: 100%;
      padding-top: 2px;
    }
    .tpl-name {
      display: inline-block;
      width: 40px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
    .journal-tab{
      display: flex;
      margin-left: 23px;
      li{
        font-size: 14px;
        background: #F6F9FA;
        color: @second-text-color;
        padding: 5px 12px;
        margin-right: 16px;
        border-radius: 44px;
        cursor: pointer;
        border: 1px solid #F1F1F1;
        height: 30px;
        &.active{
          color: @active-text-color;
          border:1px solid @active-text-color;
          font-weight: bold;
        }
        img{
          margin-left: 18px;
          transform: translateY(-6px);
        }
      }
    }
  }
  .template-list{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px 40px;
    width: 734px;
    background: #F6F9FA;
    padding: 22px 20px;
    z-index: 1000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    top: 40px;
    >div{
      padding: 10px;
      width: 140px;
      height: 194px;
      background: #fff;
      cursor: pointer;
      &:hover{
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      }
    }
    svg {
      cursor: pointer;
      path{
        stroke: #333;
      }
      &:hover{
        path{
          stroke: @active-text-color;
        }
      }
      &.disable{
        path{
          stroke: #C4C4C4;
        }
      }
    }
  }
  // 覆盖样式
  .journal-wraper{
    display: flex;
    margin-top: 8px;
    .history-journal{
      min-width: 298px;
      margin-right: 8px;
      height: calc(100vh - 180px);
      :deep(.journal-item){
        padding: 8px 22px;
        width: 298px;
        &:last-child{
          margin-bottom: 0;
        }
        .journal-top{
          margin-bottom: 0;
          .title{
            font-size: 16px;
          }
        }
        .journal-bottom .journal {
          .title{
            font-size: 14px;
            padding: 16px 0 8px;
          }
          .content{
            margin-bottom: 0;
          }
        }
        .okr-title {
          .el-progress-circle {
            width: 16px !important;
            height: 16px !important;
          }
        }

        .target-item {
          .el-progress-circle {
            width: 14px !important;
            height: 14px !important;
            transform: translateY(2px);
          }
        }
      }
      :deep(.file-list){
        padding: 5px 11px;
        margin-right: 0;
        svg{
          width: 18px;
          height: 22px;
        }
        .file-info{
          margin-left: 8px;
          .name{
            max-width: 178px;
          }
        }
        .size{
          display: none;
        }
        :deep(.img-list .el-image) {
          width: 38px;
          height: 38px;
        }
      }

      :deep(.okr-content-history) {
        font-size: 13px;
        word-break: break-word;
      }

      :deep(.journal-bottom) {
        .okr-title .okr-schedule {
          margin-left: 0;
        }

        .journal .content {
          font-size: 13px;
          word-break: break-word;
        }

        .log-okr {
          .okr-title {
            margin: 12px 0;

            & > span {
              font-size: 14px;
            }
          }

          .target-item {
            margin-bottom: 6px;
          }

          .okr-content-history {
            margin-bottom: 12px;
            line-height: 25px;
          }
        }

        .target-item-left {
          font-size: 13px;

          > span {
            font-size: 13px;
          }

          .text-ellipsis {
            min-width: 0;
            flex: 1;
            font-weight: 700;
          }
        }

        .item-progress {
          margin-left: 12px;
          transform: translateY(-2px);
        }

        .log-okr .okr-title div .el-progress {
          margin-right: 4px;
        }

        .okr-schedule {
          margin-left: 8px !important;
        }

        .okr-content > .text-ellipsis {
          line-height: 25px;
          font-size: 13px;
          font-weight: 400;
          margin-bottom: 16px;
        }

        .log-okr .okr-content:last-child > .text-ellipsis {
          margin-bottom: 0;
        }
      }

    }
    .my-journal{
      position: relative;
      background-color: @colorWhite;
      height: calc(100vh - 180px);
      min-width: 0;
      flex: 1;
      box-sizing: border-box;
    }
  }
  .more {
    text-align: center;
    font-size: 13px;
    cursor: pointer;
    line-height: 25px;
    margin-top: 8px;
    &:hover{
      color: @active-text-color;
    }
  }

.sync-okr{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  .el-switch{
    margin-right: 6px;
    :deep(.el-switch__core) {
      height: 16px;

      .el-switch__action {
        width: 12px;
        height: 12px;
      }
    }

    &.is-checked :deep(.el-switch__core .el-switch__action) {
      margin-left: -13px;
    }
  }

  .sync-okr-title {
    color: @active-text-color;
    margin-right: 12px;
    font-weight: bold;
  }

  .sync-okr-desc {
    color: @second-text-color;
  }
}
</style>
